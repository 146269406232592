import React, { useState } from 'react';
import GatsbyImage from 'gatsby-image';
import Slider from 'react-slick';
import classnames from 'classnames';

import styles from './GrowSlider.module.scss';

import Button from 'aphrodite-shared/ui/Button/Button';

const GrowSlider = ({ testimonialContent, testimonialButtons }) => {
    const [slideIndex, setSlideIndex] = useState(0);
    const [sliderRef, setSliderRef] = useState(null);

    const settings = {
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (i) => {
            setSlideIndex(i);
        }
    };

    return (
        <section className={styles.GrowSlider}>
            <h2>We’re here to help you grow</h2>
            <p>
                We take care of the loan; you get back to business. Like these
                happy customers:
            </p>
            <div className={styles.sliderControls}>
                <Slider
                    className={styles.slider}
                    ref={(slider) => setSliderRef(slider)}
                    {...settings}>
                    {testimonialContent.map((content, i) =>
                        SliderContent(content, i)
                    )}
                </Slider>
                <div className={styles.buttonContainer}>
                    {testimonialButtons.map((content, i) =>
                        SliderButton(content, i, sliderRef, slideIndex)
                    )}
                </div>
            </div>
            <p className={styles.desc}>
                These are only some examples of what you can do with the funds
            </p>
            {/* here */}
            <Button
                dataGaId="homepage_helpyougrow__cta"
                buttonClass={styles.button}
                colour="primary"
                to="#apply">
                Apply now
            </Button>
        </section>
    );
};

const SliderContent = (content, i) => (
    <div key={i} className={styles.slide}>
        <GatsbyImage
            className={styles.slideImage}
            fluid={content.image.fluid}
        />
        <div className={styles.quoteContainer}>
            <div className={styles.quoteMark}>
                <img src="https://images.ctfassets.net/835lsoe3tdrh/FkPegmePKfKNvdXQk1ZxG/d54ed8eba214fe83842fb6e4973e7901/mark.png" />
            </div>
            <div className={styles.quoteContent}>
                <p className={styles.quote}>{content.quote}</p>
                <p className={styles.business}>{content.businessName}</p>
                <p className={styles.name}>{content.customerName}</p>
            </div>
        </div>
    </div>
);

const SliderButton = (content, index, sliderRef, slideIndex) => (
    <div
        key={index}
        className={classnames(styles.button, {
            [styles.activeButton]: index === slideIndex
        })}
        onClick={() => {
            sliderRef.slickGoTo(index);
        }}>
        {index === slideIndex && <div className={styles.point}></div>}
        <div className={styles.buttonIcon}>
            <GatsbyImage fluid={content.icon.fluid} />
        </div>
        <p>{content.buttonText}</p>
    </div>
);

export default GrowSlider;
