import React from 'react';
import ReadAboutUs from '../../common/ReadAboutUs/ReadAboutUs';
import ConstellationSquares from './shapes/shapes';
import Bubble from './Bubbble/Bubble';
import styles from './Testimonials.module.scss';

const Testimonials = ({
    testimonials,
    heading,
    readAboutUsDesktopTitle,
    readAboutUsMobileTitle,
    pressReleaseSection
}) => {
    return (
        <div className={styles.Testimonials}>
            <ConstellationSquares>
                <div className={styles.upperRow}>
                    {testimonials.slice(0, 6).map((testimonial, i) => (
                        <Bubble key={i} id={i + 1} testimonial={testimonial} />
                    ))}
                </div>
                <div className={styles.middleRow}>
                    <h2
                        dangerouslySetInnerHTML={{
                            __html: heading
                        }}></h2>
                    <ReadAboutUs
                        className={styles.ReadAboutUs}
                        desktopTitle={readAboutUsDesktopTitle}
                        mobileTitle={readAboutUsMobileTitle}
                        trustpilotRating={
                            pressReleaseSection.trustpilotRating
                                .trustpilotRating
                        }
                        pressReleaseIcons={pressReleaseSection.pressPhotos}
                        trustpilotText={pressReleaseSection.trustpilotText}
                    />
                </div>
                <div className={styles.lowerRow}>
                    {testimonials.slice(6, 12).map((testimonial, i) => (
                        <Bubble
                            key={i + 6}
                            id={i + 7}
                            testimonial={testimonial}
                        />
                    ))}
                </div>
            </ConstellationSquares>
        </div>
    );
};

export default Testimonials;
