import React from 'react';
import styles from './HomePageHowItWorks.module.scss';
import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import { LongArch } from '../../../../SVGComponents/shapes';

const HomePageHowItWorks = ({ title, steps }) => {
    return (
        <section>
            <div className={styles.HomePageHowItWorks}>
                <div className={styles.title}>
                    <h2>{title}</h2>
                    <hr />
                </div>
                <div className={styles.infoContainer}>
                    {steps &&
                        steps.map((step, i) => (
                            <div className={styles.info} key={i}>
                                <div className={styles.icon}>
                                    <Img
                                        className={styles.desktopImage}
                                        fluid={step.icon.fluid}
                                    />
                                    <Img
                                        className={styles.mobileIcon}
                                        fluid={step.mobileIcon.fluid}
                                    />
                                </div>
                                <div className={styles.text}>
                                    <h3>{step.title}</h3>
                                    <ReactMarkdown
                                        className={styles.description}
                                        source={step.description.description}
                                    />
                                </div>
                                {i === 0 && (
                                    <LongArch
                                        color="london"
                                        size="sm"
                                        className={styles.rightArch}
                                    />
                                )}
                                {i === 1 && (
                                    <LongArch
                                        color="london"
                                        size="sm"
                                        className={styles.leftArch}
                                    />
                                )}
                            </div>
                        ))}
                </div>
            </div>
        </section>
    );
};

export default HomePageHowItWorks;
