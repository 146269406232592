import React from 'react';
import { graphql } from 'gatsby';
import MetaData from '../../../components/Layout/MetaData.jsx';
import CalculatorSection from '../common/CalculatorSection/CalculatorSection';
import Testimonials from '../common/Testimonials/Testimonials';
import WantToKnowMore from '../common/WantToKnowMore/WantToKnowMore';
import Questions from '../common/Questions/Questions';
import Footer from '../../../components/Footer/Footer';
import { fetchIsLoggedIn } from '../../../util/authHelpers';
import HeroBannerSection from '../../../components/LegacyPage/HeroBannerSection';
import iwocaPageStyles from '../iwocaPage.module.scss';
import styles from './Homepage.module.scss';
import NavBar from '../../../components/NavBar/NavBar';
import FeatureBarOld from '../../../components/FeatureBar/FeatureBarOld/FeatureBarOld';
import { v4 } from 'uuid';
import GrowSlider from './GrowSlider/GrowSlider';
import HomePageHowItWorks from './HomePageHowItWorks/HomePageHowItWorks';
import GetStarted from './GetStarted/GetStarted';

const Homepage = ({ data }) => {
    const { contentfulHomepagePage: pageData, trustpilot } = data;
    const {
        title,
        metaDescription,
        contactUsSection,
        allowSearchEngineScraping = true,
        includeTitlePrefixSuffix,
        url
    } = pageData;

    const isLoggedIn = fetchIsLoggedIn();

    const heroBannerButtons = {
        id: v4(),
        url: isLoggedIn ? '/my_account/' : '#compare',
        buttonText: 'Apply now',
        dataGaId: 'homepage_hero__cta',
        buttonClass: styles.heroButton
    };

    const financeSchema = setupFinancialServiceSchema(trustpilot);
    const webpageSchema = setupWebpageSchema();

    // Object is in replacement of header data having to come from legacy page in contentful\\
    const headerBannerData = {
        headline: pageData.heroBusinessOwnerTitle,
        subtitleText: {
            subtitle: pageData.heroBusinessOwnerSubtitle
        },
        underButtonText: 'Applying won’t affect your credit score',
        mobileSubtitleText: pageData.heroBusinessOwnerMobileSubtitle,
        content: [{ image: pageData.heroBusinessOwnerImage }],
        button: heroBannerButtons
    };

    return (
        <div className={iwocaPageStyles.Page}>
            <MetaData
                metaData={{
                    titleTag: title,
                    metaDescription,
                    allowSearchEngineScraping,
                    includeTitlePrefixSuffix,
                    url,
                    structuredData: [financeSchema, webpageSchema]
                }}
            />
            <NavBar />
            <HeroBannerSection data={headerBannerData} />
            <FeatureBarOld />
            <HomePageHowItWorks
                title={pageData.howItWorksTitle}
                steps={pageData.howItWorksSteps}
            />
            <CalculatorSection
                calculatorCopy={pageData.calculator}
                title={pageData.calculatorSectionTitle}
                subtitle={pageData.calculatorSectionSubtitle}
                intro={pageData.calculatorSectionIntro}
                contactBubble={pageData.contactBubble}
            />
            <GetStarted
                image={pageData.whatYouNeedToGetStartedImage}
                data={pageData.whatYouNeedToGetStarted}
            />
            <GrowSlider
                testimonialContent={pageData.testimonialSliderContent}
                testimonialButtons={pageData.testimonialSliderButtons}
            />
            <Testimonials
                testimonials={pageData.testimonials}
                heading={pageData.testimonialsHeading}
                pressReleaseSection={pageData.pressReleaseSection}
                desktopTitle={pageData.readAboutUsDesktopTitle}
                mobileTitle={pageData.readAboutUsMobileTitle}
            />
            <WantToKnowMore
                faq={pageData.faqSection.faqs}
                title={pageData.faqSection.title}
                subtitle={pageData.faqSection.subtitle.subtitle}
            />
            <Questions
                title={contactUsSection.title}
                content={contactUsSection.content.content}
                email={contactUsSection.email}
                phoneNumber={contactUsSection.phoneNumber}
            />
            <Footer />
        </div>
    );
};

export default Homepage;

const setupFinancialServiceSchema = (trustpilot) => {
    return {
        '@context': 'https://schema.org',
        '@type': 'FinancialService',
        name: 'iwoca',
        description: 'Award-winning small business finance provider.',
        url: ' https://www.iwoca.co.uk/ ',
        image: {
            '@type': 'ImageObject',
            url:
                'https://images.ctfassets.net/835lsoe3tdrh/60hozXHqoyXt2BpOMzmF2L/60004d18bd71bc07058f65091d2d69cd/logo-x2-doors.png'
        },
        logo: {
            '@type': 'ImageObject',
            url:
                'https://images.ctfassets.net/835lsoe3tdrh/60hozXHqoyXt2BpOMzmF2L/60004d18bd71bc07058f65091d2d69cd/logo-x2-doors.png'
        },
        address: {
            '@type': 'PostalAddress',
            streetAddress: '247 Tottenham Court Road',
            addressLocality: 'Bloomsbury',
            addressRegion: 'London',
            postalCode: 'W1T 7QX',
            addressCountry: 'UK'
        },
        telephone: '020 3397 3375',
        aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: trustpilot.score.trustScore,
            bestRating: 5,
            worstRating: 1,
            ratingCount: trustpilot.numberOfReviews.total
        }
    };
};

const setupWebpageSchema = () => {
    return {
        '@context': 'https://schema.org',
        '@graph': [
            {
                '@type': 'Organization',
                '@id': 'https://iwoca.co.uk/#organization',
                name: 'iwoca',
                url: 'https://iwoca.co.uk/',
                sameAs: [
                    'https://www.linkedin.com/company/iwoca/',
                    'https://www.facebook.com/iwoca/',
                    'https://twitter.com/iwoca',
                    'https://www.youtube.com/user/iwocauk ',
                    'https://www.instagram.com/iwoca/',
                    'https://en.wikipedia.org/wiki/Iwoca'
                ],
                logo: {
                    '@type': 'ImageObject',
                    '@id': 'https://iwoca.co.uk/#logo',
                    url:
                        ' https://images.ctfassets.net/835lsoe3tdrh/60hozXHqoyXt2BpOMzmF2L/60004d18bd71bc07058f65091d2d69cd/logo-x2-doors.png ',
                    caption: 'iwoca'
                },
                image: { '@id': 'https://iwoca.co.uk/#logo' }
            },
            {
                '@type': 'WebSite',
                '@id': 'https://iwoca.co.uk/#website',
                url: 'https://iwoca.co.uk/',
                name: 'iwoca',
                publisher: {
                    '@id': 'https://iwoca.co.uk/#organization'
                }
            },
            {
                '@type': 'WebPage',
                '@id': 'https://iwoca.co.uk/#webpage',
                url: 'https://iwoca.co.uk/',
                inLanguage: 'en-GB',
                name: 'Small business finance solutions & business funding',
                isPartOf: { '@id': 'https://iwoca.co.uk/#website' },
                about: { '@id': 'https://iwoca.co.uk/#organization' },
                description:
                    'Borrow £1,000 - £500,000 for cash flow, stock or investments. Fast and fair decisions.'
            }
        ]
    };
};

export const query = graphql`
    query HomepagePageQuery($id: String!) {
        contentfulHomepagePage(id: { eq: $id }) {
            title
            url
            metaDescription
            allowSearchEngineScraping
            includeTitlePrefixSuffix
            navigation {
                ... on Node {
                    ... on ContentfulNavbar {
                        name
                        menuItems {
                            name
                            item {
                                url
                                title
                            }
                        }
                    }
                    ... on ContentfulHeader {
                        ...Header
                    }
                }
            }
            heroBusinessOwnerTitle
            heroBusinessOwnerSubtitle
            heroBusinessOwnerMobileSubtitle
            heroBusinessOwnerButton
            heroBusinessOwnerSecondaryButton
            heroPrimaryButton {
                buttonText
                url
            }
            heroBusinessOwnerImage {
                fixed(width: 1800, quality: 90) {
                    ...GatsbyContentfulFixed
                }
            }
            howItWorksTitle
            howItWorksSteps {
                ...HowItWorksStep
            }
            calculator {
                title
                subtitle
                borrowUpToText
                repaymentText
                totalRepaymentText
                equalRepaymentText
                forText
                helpText
                interestText
                mobileTitle
                ctaButton {
                    buttonText
                }
                footerText {
                    footerText
                }
            }
            howItWorksTitle
            howItWorksSteps {
                ...HowItWorksStep
            }
            whatYouNeedToGetStartedImage {
                fluid(maxWidth: 300, quality: 90) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            whatYouNeedToGetStarted {
                pointOne
                pointTwo
                pointThree
                pointFour
                pointFive
            }
            testimonialSliderContent {
                image {
                    fluid(maxWidth: 300, quality: 90) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
                quote
                businessName
                customerName
            }
            testimonialSliderButtons {
                icon {
                    fluid(maxWidth: 300, quality: 90) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
                buttonText
            }
            contactBubble {
                text
                phoneNumber
            }
            testimonialsHeading
            testimonialsHeading
            testimonials {
                id
                name
                testimony
                companyName
                bubble {
                    fluid(maxWidth: 300, quality: 90) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
            }
            faqSection {
                ...faqSection
            }
            readAboutUsDesktopTitle
            readAboutUsMobileTitle
            pressReleaseSection {
                ...pressReleaseSection
            }
            contactUsSection {
                ...contactUsSection
            }
        }
        trustpilot {
            ...trustPilot
        }
    }

    fragment trustpilotRating on ContentfulTrustpilotRating {
        trustpilotRating
        numberOfReviews
        copy
    }

    fragment pressReleaseSection on ContentfulPressReleaseSection {
        trustpilotText
        pressPhotos {
            name
            photos {
                file {
                    url
                }
            }
        }
        trustpilotRating {
            ...trustpilotRating
        }
    }
    fragment faqSection on ContentfulSectionFaQs {
        title
        subtitle {
            subtitle
        }
        faqs {
            question
            answer
            color
            longAnswer {
                longAnswer
            }
        }
    }
    fragment trustPilot on trustpilot {
        numberOfReviews {
            total
        }
        score {
            trustScore
        }
    }
`;
