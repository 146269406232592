import React from 'react';
import {
    BasicGrid,
    BasicSquare,
    BasicDoughnut,
    BasicCircle,
    ArchWithCircle,
    LongArch
} from '../../../../../SVGComponents/shapes';
import styles from './shapes.module.scss';

const ConstellationShapes = ({ children }) => {
    return (
        <div className={styles.shapeContainer}>
            <BasicGrid className={styles.Grid} />
            <BasicDoughnut className={styles.BasicSnowDoughnut} color="snow" />
            <BasicSquare
                className={styles.BasicSnowSquare}
                color="snow"
                rotate="left"
            />
            <ArchWithCircle className={styles.ArchWithCircle} />
            <BasicSquare className={styles.BasicDenimSquare} color="denim" />
            <BasicDoughnut
                className={styles.BasicDenim45Doughnut}
                color="denim45"
                size="xs"
            />
            <BasicSquare className={styles.BasicCoralSquare} size="sm" />
            <BasicSquare
                className={styles.BasicDenim45Square}
                size="sm"
                color="denim65"
                rotate="left"
            />
            <BasicSquare
                className={styles.BasicMercurySquareRight}
                color="mercury"
                rotate="left"
            />
            <LongArch
                size="sm"
                className={styles.LongArchLondon}
                color="london"
            />
            <LongArch
                className={styles.LongLondon75Arch}
                color="london75"
                rotate="right"
            />
            <BasicDoughnut
                className={styles.BasicMercuryDoughnutLeft}
                color="mercury"
                size="xs"
            />
            <BasicDoughnut
                className={styles.BasicMercuryDoughnutRight}
                color="mercury"
                size="xs"
            />
            <BasicCircle
                className={styles.BasicDenimCircle}
                color="denim45"
                size="xs"
            />
                  
            {children}
        </div>
    );
};

export default ConstellationShapes;
