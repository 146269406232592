import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import DynamicTrustPilot from 'aphrodite-shared/ui/DynamicTrustpilot/DynamicTrustpilot';
import styles from './FeatureBarOld.module.scss';

const useFeatureBar = () => {
    const { contentfulFeaturesBar } = useStaticQuery(
        graphql`
            {
                contentfulFeaturesBar(
                    featuresBar: { eq: "Homepage features bar" }
                ) {
                    feature {
                        featureTitle {
                            featureTitle
                        }
                        description {
                            description
                        }
                    }
                    featuresBar
                    trustedLogo {
                        fluid(maxWidth: 200, quality: 90) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                }
            }
        `
    );

    return contentfulFeaturesBar;
};

const FeatureBar = () => {
    const featuresBar = useFeatureBar();

    return (
        <div className={styles.Features}>
            <div className={styles.featuresBarSection}>
                <div className={styles.USPContainer}>
                    <DynamicTrustPilot className={styles.DesktopTrustpilot} />
                    {featuresBar.feature.map((feature, i) => {
                        return (
                            <div className={styles.USPs} key={`feature-${i}`}>
                                <div className={styles.USPBlurb}>
                                    <h3>{feature.featureTitle.featureTitle}</h3>
                                    <p>{feature.description.description}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <DynamicTrustPilot className={styles.MobileTrustpilot} />
            <div className={styles.mobile}>
                <div className={styles.features}>
                    {featuresBar.feature.map((feature, i) => {
                        return (
                            <div className={styles.USPs} key={`feature-${i}`}>
                                <div className={styles.USPBlurb}>
                                    <h3>{feature.featureTitle.featureTitle}</h3>{' '}
                                    <p>{feature.description.description}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default FeatureBar;
