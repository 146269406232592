import React from 'react';
import GatsbyImage from 'gatsby-image';

import styles from './GetStarted.module.scss';

import TickInCircleSVG from 'aphrodite-shared/icons/TickInCircleSVG';
import Button from 'aphrodite-shared/ui/Button/Button';
import { fetchIsLoggedIn } from '../../../../util/authHelpers';
import { Amplitude, AmplitudeWebsite } from '@iwoca/frontend-tracking-library';
import { useProductName } from '../../../../components/IwModals/StepZeroModal/StepZeroModalHelpers';

const trackGetStartedCta = (ctaText, productName) => {
    const getStartedCtaEvent = AmplitudeWebsite.getStep0ModalEventWithProductTypeAndText(
        AmplitudeWebsite.GET_STARTED_STEP_0_MODAL,
        ctaText,
        productName
    );
    Amplitude.track(getStartedCtaEvent);
};

const GetStarted = ({ image, data }) => {
    const productName = useProductName();
    const {
        pointOne,
        pointTwo,
        pointThree,
        pointFour,
        pointFive,
        pointSix
    } = data;

    const points = [
        pointOne,
        pointTwo,
        pointThree,
        pointFour,
        pointFive,
        pointSix
    ];

    const loggedIn = fetchIsLoggedIn();

    return (
        <section className={styles.GetStarted}>
            <div className={styles.imageContainer}>
                <GatsbyImage fluid={image.fluid} />
            </div>
            <div className={styles.content}>
                <h2>What you need to get started</h2>
                <hr />
                <p>
                    We can accept applications from all businesses (no matter
                    the age or industry), in fact all we ask is:
                </p>
                <ul>
                    {points.map(
                        (point, i) =>
                            point && (
                                <li key={`point-${i}`}>
                                    {i !== 2 && (
                                        <>
                                            <div className={styles.tick}>
                                                <TickInCircleSVG fill="#009967" />
                                            </div>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: point
                                                }}
                                                className={styles.point}></p>
                                        </>
                                    )}
                                    {i === 2 && <p>{point}</p>}
                                </li>
                            )
                    )}
                </ul>
                <div className={styles.buttonWrapper}>
                    <div>Ready to apply?</div>
                    <Button
                        onClick={() => {
                            trackGetStartedCta('Get started', productName);
                        }}
                        to={loggedIn ? '/my_account/' : '#compare'}
                        dataGaId="homepage_getstarted__cta"
                        buttonClass={styles.button}
                        colour="primary">
                        Get started
                    </Button>
                </div>
                <p className={styles.copy}>
                    The document requirements may change according to the loan
                    size and type of company
                </p>
            </div>
        </section>
    );
};

export default GetStarted;
